// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$frabel-backoffice-primary: mat.define-palette(mat.$indigo-palette);
$frabel-backoffice-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$frabel-backoffice-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$frabel-backoffice-theme: mat.define-light-theme((color: (primary: $frabel-backoffice-primary,
        accent: $frabel-backoffice-accent,
        warn: $frabel-backoffice-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($frabel-backoffice-theme);

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-Regular.ttf");
}

:root {
  //fonts
  --primary-font: "Roboto";
  // colors
  --primary: #4FCA42;
  --primary-opacity: rgba(79, 202, 66, 0.15);
  --primary-dark: #2CBF50;
  --primary-light: #C8EF12;
  --secondary: #FAA715;
  --secondary-light: #FCB711;
  --white: #ffffff;
  --black: #000000;
  --black-low-opacity: rgba(0, 0, 0, 0.2);
  --red: #F95531;
  --blue: #1D90E3;
  --green: #4FCA42;
  --background-grey: #f5f5f5;
  --background-disabled-grey: #f9f9f9;
  --soft-grey: #e6e6e6;
  --grey: #666666;
  --light-grey: #dedede;
  --lighter-grey: #f2f2f2;
  --dark-grey: rgb(102, 102, 102, 0.4);
  --grey-placeholder: #d9d9d9;
  --grey-inactive: #e3e3e3;
  // tables
  --warning-color: #ffc4003c;
  --salmon: #ce6851;
  --row-shadow-box: rgb(102, 102, 102, 0.4);
  --separator: #e4e7eb;
  // Redux logger colors
  --prev-state: #9E9E9E;
  --action: #03A9F4;
  --next-state: #4CAF50;
  --error: #F20404;
}

* {
  font-family: var(--primary-font);
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: var(--primary-font), "Helvetica Neue", sans-serif;
}

input {
  padding-left: 8px;
  align-items: center;
  background-color: var(--white);
  border: solid 1px var(--soft-grey);
  height: 40px !important;
  color: var(--black);
  width: 100%;
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 0;
}

input:focus {
  outline: none !important;
}

button {
  border: none;
  background-color: transparent;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--primary-dark);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--primary);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-ripple .mat-ripple-element {
  background-color: var(--primary-light);
}

.mat-progress-bar-fill::after {
  background-color: var(--primary);
}

.mat-progress-bar-buffer {
  background-color: var(--grey-inactive);
}

.mat-progress-bar {
  border-radius: 2px;
}

.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: var(--primary) !important;
}

.mat-checkbox:not(.mat-checkbox-disabled) .mat-checkbox-ripple .mat-ripple-element {
  background-color: var(--primary) !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--primary);
}

.red {
  color: var(--red);
}

.blue {
  color: var(--blue);
}

.green {
  color: var(--green);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}